import { defineStore } from "pinia";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    notifications: [
      {
         id: 1,
         type: "info",
         content: [
          "The DFM projections for Italy are currently flawed following a re-indexing of the GDP target series by the Italian Statistical Office (Istat). We are working on it. None of the other projections is affected.",
          //     "The drop in the 2023Q3 nowcast for Swiss GDP on August 26, 2024 is due to a bug. See here for more: <a href='https://blognowcastinglab.wordpress.com/2024/08/29/a-drop-in-the-swiss-gdp-nowcast-that-is-actually-none/' target='_blank'> https://blognowcastinglab.wordpress.com</a>. We will repair this, do a backcast for Switzerland from August 26 onwards -- and the drop in GDP growth should go away.",
          //     "Check out our Nowcasting Lab blog on <a href='https://blognowcastinglab.wordpress.com' target='_blank'> https://blognowcastinglab.wordpress.com</a>. The blog posts report on our work on the Nowcasting Lab project.", 
          //     "We recently made the backcasting facilitites of the Nowcasting Lab more flexible. Now, we can do backcasts / real-time out-of-sample forecasting exercises for individual countries and individual models without the need to run all countries / models altogether. This is beneficial for, e.g., testing the out-of-sample performance of newly integrated nowcasting models based on the real-time data history in the Nowcasting Lab database.",
           //     "Unfortunately, some bugs have occurred during the code adjustment. As a consequence, the RMSFEs and the upper and lower bounds derived from them are now displayed incorrectly on the detail pages. In addition, the impacts are displayed incorrectly. We will fix these bugs as soon as possible. The mean now-/forecasts of the various models are not affected by this.",
           //     "On March 2, we implemented an improved version of the elastic net variable pre-selection. The change of the pre-selection algorithm resulted for some countries in large one-time impacts of the variable selection category and, by consequence, in large one-time revisions to the GDP now-/forecasts at the day of the first update of the GDP series after March 2. (Note that the pre-selection algorithm is run after each GDP release, hence, the changes did not become effective earlier.) For instance, for Spain and Portugal this occurred on March 24, 2023.",
           //     "Due to a change of data provider, we have lost a (small) part of our data series and gained other series instead. This resulted on April 6, 2022, for some models in some countries, in a structural break in the GDP projections.",
         ],
      },
    ],
  }),
});

