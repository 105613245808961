<template>
  <div class="column">
    <div class="field has-addons has-addons-centered">
      <p class="control">
        <button
          class="button is-medium"
          :class="{ 'is-primary': !contributionChartActive }"
          @click="contributionChartActive = false"
        >
          <span class="button-label"> Forecast Impacts </span>
          <ncl-info-icon
            location="bottom"
            text="The forecast impacts graph shows, for each day, the day-to-day changes in the contributions of the pooled forecasts from the different variable categories. The sum of all impacts equals the day-to-day change of the overall pooled forecast."
            :secondaryColor="!contributionChartActive"
          />
        </button>
      </p>

      <p class="control">
        <v-tooltip
          location="bottom"
          text="The selected model does not calculate forecast contributions."
          v-if="!contributionsAvailable"
          activator="#contributions-button"
        />
        <button
          :disabled="!contributionsAvailable"
          id="contributions-button"
          class="button is-medium"
          :class="{ 'is-primary': contributionChartActive }"
          @click="contributionChartActive = true"
        >
          <span class="button-label"> Forecast Contributions </span>
          <ncl-info-icon
            location="bottom"
            text="The forecast contributions chart shows, for each day, the contributions of the pooled forecasts stemming from different variable categories to the overall pooled forecast. The sum of all contributions equals the overall pooled forecast. The About site explains how these contributions are calculated."
            :secondaryColor="contributionChartActive"
          />
        </button>
      </p>
    </div>
    <highcharts
      v-if="maxTime > 0"
      :options="chartOptions"
      ref="testChart"
      :constructor-type="'stockChart'"
      style="height: 700px"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import stockInit from "highcharts/modules/stock";
import Highcharts from "highcharts";
import { debounce } from "lodash-es";
import { addDays, differenceInMilliseconds, startOfDay } from "date-fns";
import { mapActions, mapState } from "pinia";
import { useDetailStore } from "@/stores/detail";
import NclInfoIcon from "@/components/NclInfoIcon.vue";

stockInit(Highcharts);

export default {
  components: { highcharts: Chart, NclInfoIcon },
  props: {
    country: String,
    targetperiod: String,
    targetvariable: String,
    indicator: { type: String, default: "en" },
  },
  data() {
    return {
      inactiveCategories: [],
      contributionChartActive: false,
      clickedDayTime: null,
      hoveredDayTime: null,
      chart: null,
    };
  },
  computed: {
    ...mapState(useDetailStore, [
      "model",
      "getOutputData",
      "getMaxTimestamp",
      "getForecastContributionsData",
      "getForecastImpactData",
      "getCategories",
    ]),
    activeDayTime() {
      if (this.clickedDayTime) {
        return this.clickedDayTime;
      }
      return this.hoveredDayTime;
    },
    maxTime() {
      let max = !this.getMaxTimestamp ? 0 : this.getMaxTimestamp;
      max = Math.max(0, max);
      return max;
    },
    activeBarChartData() {
      if (this.contributionChartActive) {
        return this.getForecastContributionsData;
      }
      return this.getForecastImpactData;
    },
    chartOptions() {
      let xAxisName = "";
      if (this.targetvariable === "gdp") {
        if (this.contributionChartActive) {
          xAxisName = "Contributions to q-o-q growth in pp";
        } else {
          xAxisName = "Change in contr. to q-o-q growth in pp";
        }
      }

      let axisTitle = "Q-o-q growth in %";

      // Padding the data with null values so get "whitespace" after the chart where there might not be data
      let startDate = startOfDay(
        new Date(
          this.getOutputData[this.getOutputData.length - 1]?.x ?? Date.now(),
        ),
      );
      let endDate = startOfDay(new Date(this.maxTime));
      let paddedDates = [];
      if (this.getOutputData.length) {
        while (differenceInMilliseconds(addDays(startDate, 1), endDate) < 0) {
          paddedDates.push({
            selected: false,
            x: startDate.valueOf(),
            y: null,
          });
          startDate = addDays(startDate, 1);
        }
      }

      let series1 = {
        showInLegend: false,
        name: "GDP growth forecast",
        data: [...this.getOutputData, ...paddedDates],
        showInNavigator: true,
        yAxis: 0,
        marker: {
          enabled: true,
          radius: 0.1,
          states: {
            select: {
              fillColor: "orange",
              lineWidth: 0.5,
              radius: 7,
            },
            hover: {
              radius: 7,
            },
          },
        },
      };

      return {
        legend: {
          enabled: true,
        },
        chart: {
          marginLeft: 100,
          events: {
            load: (function (self) {
              return function () {
                self.chart = this; // saving chart reference in the component
                // saving N/A text in the middle of the bottom (bar) chart
                // to be able to set its opacity based on availability of bar data
                self.naLabel = this.renderer
                  .label("N/A", this.chartWidth / 2, 400)
                  .add()
                  .css({
                    color: "gray",
                    fontWeight: 600,
                    fontSize: 50,
                    opacity: "0%",
                  });
              };
            })(this),
          },
        },
        xAxis: {
          type: "datetime",
          title: {
            text: "Date of forecast",
          },
          dateTimeLabelFormats: {
            month: "%b %Y",
            year: "%b %Y",
          },
          max: this.maxTime,
          events: {
            afterSetExtremes: debounce(() => {
              this.syncYAxis();
            }, 20),
          },
        },
        yAxis: [
          {
            title: {
              reserveSpace: false,
              text: axisTitle,
              x: -3,
            },
            height: "40%",
            labels: {
              enabled: true,
              x: 1,
              y: 5,
              format: "{value:.2f} ",
            },
            opposite: false,
            minRange: !this.contributionChartActive ? 0.2 : undefined,
            showLastLabel: true,
          },
          {
            title: {
              reserveSpace: false,
              text: xAxisName,
              x: -3,
            },
            top: "60%",
            height: "40%",
            labels: {
              enabled: true,
              x: 30,
              y: 5,
              format: "{value:.2f}",
            },
            minTickInterval: 0.01,
            opposite: false,
            showLastLabel: true,
          },
        ],
        series: [series1, ...this.activeBarChartData],
        plotOptions: {
          column: {
            stacking: "normal",
            tooltip: {
              shared: true,
              valueDecimals: 2,
              valueSuffix: "pp",
            },
          },
          series: {
            allowPointSelect: false,
            cursor: "pointer",
            point: {
              events: {
                click: (day) => {
                  if (this.clickedDayTime === day.point.x) {
                    this.clickedDayTime = null;
                  } else {
                    this.clickedDayTime = day.point.x;
                  }
                },
                mouseOver: (day) => {
                  this.hoveredDayTime = day.target.x;
                },
                mouseOut: () => {
                  this.hoveredDayTime = null;
                },
              },
            },
            events: {
              legendItemClick: (item) => {
                const name = item.target.name;
                const color = item.target.color;
                const isInvisible = item.target.visible; // when visible true, then it was visible before the click and will be invisible after this event!

                if (isInvisible) {
                  this.inactiveCategories.push({
                    name: name,
                    color: color,
                  });
                } else {
                  this.inactiveCategories = this.inactiveCategories.filter(
                    (o) => o.name !== item.target.name,
                  );
                }
              },
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix: "%",
            },
            animation: false,
          },
        },
        rangeSelector: {
          buttons: [
            {
              type: "month",
              count: 1,
              text: "1m",
              title: "View 1 month",
              events: {
                click: () => {
                  if (this.getOutputData.length) {
                    let lastDate =
                      this.getOutputData[this.getOutputData.length - 1].x;
                    let oneMonthBefore = lastDate - 30 * 24 * 60 * 60 * 1000;
                    this.chart.xAxis[0].setExtremes(oneMonthBefore, lastDate);
                  }

                  return false;
                },
              },
            },
            {
              type: "all",
              text: "All",
              title: "View all",
            },
          ],
        },
        navigator: {
          adaptToUpdatedData: false,
        },
      };
    },
    activeCategories() {
      if (this.getCategories) {
        return this.getCategories.filter(
          (obj) => !this.inactiveCategories.some((x) => x.name === obj.name),
        );
      }
      return [];
    },
    contribNotApplicableLabel() {
      switch (this.model) {
        case "dfm":
        case "dfm_Q419":
        case "dfm_excl_covid":
          return "By construction, the dynamic factor model does not generate forecast contributions but forecast impacts.";
        case "mf-esn":
          return "Currently, the multi-frequency echo state network models do not generate forecast contributions.";
        case "all":
          return "By construction, the dynamic factor model does not generate forecast contributions. As a consequence, the pooled forecast, which results from pooling the forecasts from all model classes, does not come with forecast contributions neither.";
        default:
          return "";
      }
    },
    contributionsAvailable() {
      return (
        [
          "all",
          "dfm",
          "dfm_Q419",
          "dfm_excl_covid",
          "mf-esn",
          "randomwalk",
          "rollmean",
          "ar_iterative_bic",
          "arima",
          "ar_direct_bic",
        ].indexOf(this.model) < 0
      );
    },
  },
  watch: {
    activeDayTime: debounce(function (newActiveDayTime) {
      if (!newActiveDayTime) {
        return;
      }

      this.$emit("activedaytimechange", {
        x: newActiveDayTime,
      });

      this.getForecastContributionsData.forEach((dataCategorie) => {
        dataCategorie.data.map((ele) => {
          ele.className = "bar-inactive";
          if (ele.x == newActiveDayTime) {
            ele.className = "bar-active";
          }
        });
      });
      this.getForecastImpactData.forEach((dataCategorie) => {
        dataCategorie.data.map((ele) => {
          ele.className = "bar-inactive";
          if (ele.x == newActiveDayTime) {
            ele.className = "bar-active";
          }
        });
      });
    }, 200),
    activeCategories(newActiveCategories) {
      this.$emit("activecategorieschange", newActiveCategories);
    },
    activeBarChartData(newData) {
      this.syncYAxis();
      if (this.naLabel) {
        if (newData.length > 0) {
          this.naLabel.attr({ style: "opacity: 0%" });
        } else {
          this.naLabel.attr({ style: "opacity: 30%" });
        }
      }
    },

    // When contribution view is active and we change model,
    // switch to impact view. staying in contrib would be illegal
    // as that is not accessible otherwise.
    model() {
      this.contributionChartActive =
        this.contributionChartActive && this.contributionsAvailable;
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions(useDetailStore, [
      "loadOutputData",
      "loadImpactData",
      "loadContributionData",
    ]),
    syncYAxis() {
      if (!this.chart || !this.chart.yAxis) {
        return;
      }
      if (this.contributionChartActive) {
        const extremes0 = this.chart.yAxis[0].getExtremes();
        const extremes1 = this.chart.yAxis[1].getExtremes();

        const min = Math.min(extremes0.dataMin, extremes1.dataMin);
        const max = Math.max(extremes0.dataMax, extremes1.dataMax);
        this.chart.yAxis[0].setExtremes(min, max);
        this.chart.yAxis[1].setExtremes(min, max);
      } else {
        // No syncing, just use highcharts values
        // Calling setExtremes with no parameters seems to let Highcharts auto calculate axis.
        this.chart.yAxis[0].setExtremes();
        this.chart.yAxis[1].setExtremes();
      }
    },
    async loadData() {
      const impactPromise = this.loadImpactData({
        country: this.country,
        targetVariable: this.targetvariable,
        targetPeriod: this.targetperiod,
        model: this.model,
      });
      const contributionPromise = this.loadContributionData({
        country: this.country,
        targetVariable: this.targetvariable,
        targetPeriod: this.targetperiod,
        model: this.model,
      });
      const outputPromise = this.loadOutputData({
        country: this.country,
        targetVariable: this.targetvariable,
        targetPeriod: this.targetperiod,
        model: this.model,
        indicator: this.indicator,
      });
      await Promise.all([impactPromise, contributionPromise, outputPromise]);
      this.hoveredDayTime =
        this.getOutputData[this.getOutputData.length - 1]["x"];
    },
  },
};
</script>

<style lang="scss">
.bar-active {
  opacity: 1;
}

.bar-inactive {
  opacity: 0.4;
}
</style>
